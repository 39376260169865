<template>
	<div>
		<el-menu
		:default-active="$route.path" 
		mode="horizontal" 
		active-text-color="#ffffff"
		text-color="#737478"
		router>
		  <el-menu-item 
		  :index="item.path" 
		  v-for="(item,index) in menu" 
		  :key="index">
		    {{item.name}}
		  </el-menu-item>
		</el-menu>
	</div>
</template>

<script>
	import menuData from "./menu-data.js";
	export default {
		props:{
			listName:{
			  type: String,
			  default: ''
			}
		},
		data() {
			return {
				menu: menuData[this.listName]
			}
		},
		watch:{
		  listName(newVal,oldVal){
		    this.menu = menuData[newVal]
		  }
		}
	}
</script>

<style lang="less" scoped>
.el-menu {
  height: 30px;
	border:  1px solid #dcdfe6;
	display: inline-block;
	border-radius: 5px;
	overflow: hidden;
	margin-top: 20px;
  .el-menu-item {
    height: 30px;
    line-height: 20px;
		font-size: 12px;
		padding: 5px 15px;
    box-sizing: border-box;
		border-bottom: none;
		border-right: 1px solid #dcdfe6;
  }
	.el-menu-item:last-child{
		border-right: none;
	}
	.is-active{
		background-color: #20a0ff !important;
		color: #FFFFFF;
	}
	.el-submenu__title:hover{
		
	}
	.el-menu-item:not(.is-disabled):hover{
		
	}
  .el-menu-item:hover{
    
  }
}	
</style>
